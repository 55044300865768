import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Footer.module.css';
import '../../App.css';

import footer from './images/footer_solid.png';

function Footer() {
    return (
        <footer>
            <div className='page-container'>
                <div className='desktop-padding' />
                <div className='page'>
                    <hr />
                    <div className={styles['footer-container']}>
                        <nav className={styles['footer-links']}>
                            <ul>
                                <li><Link to={`/find`}>Contact Us</Link></li>
                                <li><a href="https://www.instagram.com/goodfatpastry" target="_blank" rel="noopener noreferrer">@goodfatpastry</a></li>
                            </ul>    
                        </nav>
                        <p className={styles['copyright']}>&#169; 2024 Good Fat Pastry</p>  
                    </div> 
                </div>
                <div className='desktop-padding' /> 
            </div>  
            <div className={styles['image-container']}>
                <img src={footer} alt="Good Fat Pastry footer" className={styles['footer-image']} />
            </div>
        </footer>
    );
};

export default Footer; 