import React, { useState } from 'react';
import css from "classnames";
import styles from './Alert.module.css';

export default function Alert({ type, message }) {
    const [isShow, setIsShow] = useState(true);

    const handleClose = (e) => {
        e.preventDefault();
        setIsShow(false);
    }
    
    return (
        <div className={css(styles.alert, styles[type], !isShow && styles.hide)}>
            <p>
                {message}
                <span className={styles.closebtn} onClick={handleClose}>
                    &times;
                </span>
            </p>
        </div>
    );
};