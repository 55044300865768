import React from 'react';
import logoSmall from './images/title_sml.png';
import logoLarge from './images/title_lge.png';
import styles from './Header.module.css'; 

function Header() {
    return (
        <header>
            <div className={styles['logo-container']}>
                <source media="(min-width: 651px)" srcSet={logoLarge} />
                <source media="(max-width: 650px)" srcSet={logoSmall} />
                <img src={logoSmall} alt="Good Fat Pastry" />
            </div>
        </header>
    );
};

export default Header;