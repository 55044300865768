import React from 'react';
import styles from './Sub-Header.module.css';

function SubHeader( {children} ) {
    return ( 
        <div className={styles['sub-header']}>
            <div className={styles['sub-container']}>
                {children}
            </div>
        </div>
    );
};

export default SubHeader;