import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-gesture-handling';

import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import markerIcon from './images/map_pin.png';

const MapStyle = {
    height: '100%',
    width: '100%',
    borderRadius: '5px',
}

const DefaultMarker = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    iconSize: [35, 54],
    iconAnchor: [10, 40],
    popupAnchor: [0, -32]
  });

L.Marker.prototype.options.icon = DefaultMarker;


function MapPlaceholder() {
    return (
        <p>
        Location of Good Fat Pastry.{' '}
        <noscript>You need to enable JavaScript to see this map.</noscript>
        </p>
    )
}
  
function MapComponent() { 
    const position = [-33.716501,150.3109558]; 

    return ( 
        <MapContainer 
            center={position}
            zoom={18}
            minZoom={12}
            maxZoom={19}
            gestureHandling={true}
            style={MapStyle}
            placeholder={MapPlaceholder}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright"><small>OpenStreetMap</a> contributors</small>'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxNativeZoom={19}
                maxZoom={19}
                minZoom={12}
            />
            <Marker position={position}>
                <Popup>
                    <p><strong>Good Fat Pastry</strong></p>
                    <p>185 Katoomba Street<br />
                    Katoomba</p>
                </Popup>
            </Marker>
        </MapContainer>
    )
};

export default MapComponent; 