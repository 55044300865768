import React from 'react';
import SubHeader from '../../Sub-Header/Sub-Header'

import styles from './Giving.module.css';
import '../../../App.css';

import donations from './images/oaf_logo.png';
import donationsMob from './images/oaf_logo_stacked.png';

function Giving() { 
    return (
        <div>
            <SubHeader>
                <div className={styles['giving-sub-container']}>
                    <p>For those beyond our own tables we find other ways to share.</p>
                </div>
            </SubHeader>
            <div className='page-container'>
                <div className='desktop-padding' />
                <div className='page'>
                    <article className='text-container'>
                        <div className={styles['text-box-giving']}>
                            <p>
                                Good Fat Pastry operates as a means to generate financial benefit 
                                for others in need of support. The purpose is flexible, meaning 
                                funding can be directed to different charities as demand or potential for impact changes.
                                </p>
                                <p>
                                Currently, the benefit generated by Good Fat Pastry is being directed 
                                toward One Acre Fund - an agricultural service provider that supports 
                                Africa's smallholder farmers to build resilient communities.
                            </p>
                        </div>
                    </article>
            
                    <hr />

                    <div className='text-container'>
                        <div className={styles['donation-image-container']}>
                            <picture>   
                                <source media="(min-width: 491px)" srcSet={donations} />
                                <source media="(max-width:490px)" srcSet={donationsMob} />
                                <img src={donations} alt="One Acre Fund Logo" />
                            </picture>
                        </div>
                        <blockquote className={styles['text-box-blockquote']}>
                            <p>
                                More than 50 million families in Sub-Saharan Africa earn 
                                a living from small farms, which produce 80 percent of the continent&#8217;s 
                                food. But harvests still lag far behind the rest of the world. 
                                We supply the financing and training these farmers need to grow their 
                                way out of hunger and build lasting pathways to prosperity.
                            </p>
                            <p>
                                Instead of giving handouts, we invest in farmers to help them increase 
                                their yields and their profits. When farmers become more productive, 
                                they help feed healthy communities and create thriving economies. They 
                                also reduce environmental pressure by doing more with less land. 
                            </p>
                        </blockquote>
                        <div className={styles['learn-more-container']}>
                            <div className={styles['learn-more']}>
                                <p>Learn more at</p>
                            </div>
                            <a href="https://oneacrefund.org" className="button" target="_blank" rel="noopener noreferrer">One Acre Fund</a>
                        </div>
                    </div>
                </div>
                <div className='desktop-padding' />
            </div>
        </div> 
    );
};

export default Giving; 