/**
 * css properties in App.css
 **/

import React from 'react';

function ImageContainer ({reel, alt}) {
    return(
        <section className='image-container'>
            <div className='equal-height image-row'> 
                <div className='image-cell'>
                    <img src={reel['image1']} alt={alt['image1Alt']} />
                </div>
                <div className='image-cell image-cell-desktop'>
                    <img src={reel['image2']} alt={alt['image2Alt']} />
                </div>
                <div className='image-cell'>
                    <img src={reel['image3']} alt={alt['image3Alt']} />
                </div>
            </div>
            
            <div className='image-row'> 
                <div className='image-cell image-cell-mobile'>
                    <img src={reel['image2']} alt={alt['image2Alt']} />
                </div>
            </div>
        </section>
    );
}

export default ImageContainer; 