import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Alert from '../../../PageStyling/Alert';

import './Enquiry-Form.css';

const errorMsg = "Something went wrong. If problem persists, please contact hello@goodfatpastry.au";
const successMsg = "Thank you for contacting us, we'll be in touch shortly.";



const EnquiryForm = () => {
    const [state, setState] = useState({
        name: '',
        number: '',
        email: '',
        message: '',
    });

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const sendEmail = event => {
        event.preventDefault();
        setResult(null);
        setLoading(true);

        fetch('https://worker.michael-eas.workers.dev', {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({ ...state })
        })
        .then(res=> {
            if (!res.ok) {
                throw new Error(`${errorMsg}`);
            }
            return res.json();
        })
        .then(() => { 
            setResult({ success: true, message: `${successMsg}`});
            setState({ 
                name: '', 
                number: '', 
                email: '', 
                message: ''
            });
        })
        .catch(() => { 
            setResult({ 
                success: false,
                message: errorMsg
            })
        })
        .finally(() => { 
            setLoading(false); 
        });
    };

    const onInputChange = event => {
        const { name, value } = event.target; 

        setState({
            ...state,
            [name]: value
        });
    };
    
    return (
        <>
            { result && (
                <TransitionGroup>
                    <CSSTransition
                        timeout={300}
                        classNames="transition"
                    >
                        <div className="resultContainer">
                            <Alert 
                                type={result.success ? 'success' : 'error'} 
                                message={result.message}
                            />
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            )} 
        

            <form onSubmit={sendEmail} className="enquiry-form">
                <Form.Group controlId="name" className="group">
                    <Form.Label className="label">Name</Form.Label>
                    <Form.Control
                        type="text" required
                        name="name"
                        className="control"
                        value={state.name}
                        placeholder="Tell us your name"
                        onChange={onInputChange}
                    />
                </Form.Group>

                <Form.Group controlId="number" className="group">
                    <Form.Label className="label">Contact Number</Form.Label>
                    <Form.Control
                        type="tel"
                        name="number" minLength="8" maxLength="10"
                        className="control"
                        value={state.number}
                        placeholder="If you'd like to"
                        onChange={onInputChange}
                    />
                </Form.Group>

                <Form.Group controlId="email" className="group">
                    <Form.Label className="label">Email Address</Form.Label>
                    <Form.Control
                        type="email" required
                        name="email"
                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                        className="control"
                        value={state.email}
                        placeholder="yum@ilovepastries.com"
                        onChange={onInputChange}
                    />
                </Form.Group>

                <Form.Group controlId="message" className="group">
                    <Form.Label className="label">Message</Form.Label>
                    <Form.Control
                        as="textarea" required
                        name="message"
                        className="control message-box"
                        value={state.message}
                        rows={5}
                        placeholder="What can we help with?"
                        onChange={onInputChange}
                    />
                </Form.Group>

                <div className="button-container">
                    <Button type="submit" className="submit-btn">
                        {loading ? 
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                className="spinner-grow"
                                role="status"
                                aria-hidden="true"
                            >
                                <span className="visually-hidden">Sending....</span>
                            </Spinner>
                            : "Submit" 
                        }
                    </Button>
                </div>
            </form>
        </>
    )
};

export default EnquiryForm; 