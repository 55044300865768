import React from 'react';

import SubHeader from '../../Sub-Header/Sub-Header';
import EnquiryForm from './EnquiryForm/Enquiry-Form';
import StreetMap from './StreetMap/Street-Map';
import ImageContainer from '../../PageStyling/ImageContainer';

import styles from './Find.module.css';
import '../../../App.css';


const findReel = {
    "image1": require('./images/choc-scrolls.jpg'),
    "image2": require('./images/croissant-cross-section.jpg'),
    "image3": require('./images/peanut-cookie.jpg')
};

const altImage = { 
    "image1Alt" : "Chocolate Scrolls",
    "image2Alt" : "Cross section of a croissant",
    "image3Alt" : "Peanut Miso Cookie",
}

function Find() { 
    return ( 
        <div>
            <SubHeader>
                <div className={styles['find-sub-container']}>
                    <p>Thanks to the generosity of Hominy Bakery we're currently popping up in their shopfront every Sunday.</p>
                    <p>185 Katoomba Street</p>
                    <p>From 8am</p>
                </div>
            </SubHeader>
            <div className='page-container'>
                <div className='desktop-padding' />
                <div className='page'>     
                    <div className={styles['locate-container']}>
                        <div className={styles['enquiry-container']}>
                            <EnquiryForm />
                        </div>
                        <div className={styles['map-container']}>
                            <div className={styles['map-box']}>
                                <StreetMap />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <ImageContainer reel={findReel} alt={altImage} />
                </div>
                <div className='desktop-padding' />
            </div>
        </div>
    );
};

export default Find; 