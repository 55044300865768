import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';

import Header from './components/Header/Header.jsx';
import NavBar from './components/Nav-Bar/Nav-Bar.jsx';
import Footer from './components/Footer/Footer.jsx';

import Home from './components/Pages/Home/Home.jsx';
import Giving from './components/Pages/Giving/Giving.jsx';
import Story from './components/Pages/Story/Story.jsx';
import Find from './components/Pages/Find/Find.jsx';

import ScrollToTop from './components/PageStyling/ScrollToTop.jsx';

import './App.css';

function App() {
    const location = useLocation(); 

    return (
            <ScrollToTop>
                <Header />
                <NavBar />
                <TransitionGroup>
                    <CSSTransition
                        key={location.key}
                        timeout={300}
                        classNames='page-transition'
                    >
                        <Routes location={location}>
                            <Route path='/' element={<Navigate to='/home' />} />
                            <Route path='/home' element={<Home />} />
                            <Route path='/giving' element={<Giving />} />
                            <Route path='/story' element={<Story />} />
                            <Route path='/find' element={<Find />} />
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
                <Footer />
            </ScrollToTop>
    );
}

export default App;
