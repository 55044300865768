import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import navData from './nav-data.json';
import styles from './Nav-Bar.module.css';

const images = {
   "baker_1": require('./images/baker_1.png'),
   "baker_2": require('./images/baker_2.png'),
   "baker_3": require('./images/baker_3.png'),
   "baker_4": require('./images/baker_4.png')
};

function NavBar() {
   const [hoverState, setHoverState] = useState({});
   const location = useLocation().pathname.slice(1);

   const handleCursorEnter = (id, fontColour, backgroundColour) => {
      setHoverState(prevState => ({
         ...prevState,
         [id]: { fontColour, backgroundColour, fontStyle:'italic' }
      }));
   };

   const handleCursorExit = (id) => {
      setHoverState(prevState => ({
         ...prevState,
         [id]: { fontColour: 'inherit', fontStyle: null, backgroundColour: null }
      }));
   };

   const getStyle = (pageId) => {
      if (pageId === location || (pageId === "" && location === "home")) {
         let activePage = navData.navOptions.find((option) => option.id === pageId);
         return {
            fontColour: activePage.fontColour,
            backgroundColour: activePage.backgroundColour,
            fontStyle: "italic",
         };
      }
      return hoverState[pageId] || { fontColour: 'inherit', backgroundColour: 'inherit', fontStyle: 'normal' };
   };

   return (
      <div className={styles["nav-container"]}>
         {navData.navOptions.map(({ id, imageSrc, backgroundColour, fontColour }) => (
            <Link
               to={`/${id}`}
               className={styles["nav-bar-link"]}
               key={id}
               style={{
                  backgroundColor: getStyle(id).backgroundColour,
                  color: getStyle(id).fontColour,
                  fontStyle: getStyle(id).fontStyle,
               }}
               onMouseOver={() => handleCursorEnter(id, fontColour, backgroundColour)}
               onMouseLeave={() => handleCursorExit(id)}
            >
               <ul className={styles["nav-bar"]}>
                  <li>
                     <div className={styles["nav-tile"]} style={{ background: backgroundColour }}>
                        <img src={images[imageSrc]} alt={id} className={styles["nav-img"]} />
                     </div>
                     <div className={styles["nav-text"]}>
                        {id}
                     </div>
                  </li>
               </ul>
            </Link>
         ))}
      </div>
   );
};

export default NavBar;