import React from 'react';
import { Link } from 'react-router-dom';
import SubHeader from '../../Sub-Header/Sub-Header';
import ImageContainer from '../../PageStyling/ImageContainer';

import '../../../App.css';
import styles from './Home.module.css';

const homeReel = {
    "image1": require('./images/berry-danish.jpg'),
    "image2": require('./images/croissant2.jpg'),
    "image3": require('./images/stickydate.jpg')
};

const altImage = { 
    "image1Alt" : "Berry Danish",
    "image2Alt" : "Cross section of a croissant",
    "image3Alt" : "Gluten free sticky date cake",
}


function Home() {
    return (
        <>
            <SubHeader> 
                <div className={styles.container}>
                    <div>
                        <p className={styles.bold}>Good</p>
                    </div>
                    <div className={styles.middle}>
                        <p>is</p>
                    </div>
                    <div>
                        <span className={styles.bold}>tasty<br />sharing joy<br />giving</span>
                    </div>
                </div>
            </SubHeader>
            <div className='page-container'>
                <div className='desktop-padding' />
                <div className='page'>
                    <section className='text-container'> 
                        <div>
                            <p>
                                Good Fat Pastry creates scrumptious plant based pastries <br />
                                for your enjoyment and <br/>
                                donates proceeds to people in need. <br/>
                                <br />
                                Do yourself good by eating tasty things. Do others good by<br />supporting their needs.
                            </p>
                            <div style={{marginTop: 25}}>
                                <Link to={`/giving`}
                                    className='button'>Learn More</Link>
                            </div>
                        </div>
                    </section>

                    <hr />
                
                    <ImageContainer reel={homeReel} alt={altImage} />

                    <hr />

                    <article className='text-container'> 
                        <div className='text-box'>
                            <p>Pop-up in the Hominy Bakery shopfront
                                <br />
                                <strong>185 Katoomba St</strong>
                                <br />
                                Sundays from 8am
                            </p>
                        </div>
                    </article>
                </div>
                <div className='desktop-padding' />
            </div>
        </>
    );
};

export default Home; 